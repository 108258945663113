import {PureComponent} from 'react';
import {Form} from 'semantic-ui-react';
import {map, uniqueId, compact} from 'lodash';

import Field from './Field';
import generateOptionsFromSchema from '../generateOptionsFromSchema';

export default class RadioGroupInput extends PureComponent {
  name = uniqueId(this.props.name);

  render() {
    const {value, disabled, onChange, schema, required} = this.props;
    const options = generateOptionsFromSchema(schema);
    return (
      <Field
        label={schema?.title}
        description={schema?.description}
        required={required}
        disabled={disabled}
      >
        {({'aria-labelledby': ariaLabelledBy, 'aria-describedby': ariaDescribedBy}) =>
          <Form.Group inline>
            {map(options, (option, index) => {
              const labelId = `radio-${this.name}-${index}`;
              return (
                <Form.Radio
                  key={this.name + option.key}
                  name={this.name}
                  label={
                    <label id={labelId}>{option.text}</label>
                  }
                  aria-labelledby={!disabled && compact([ariaLabelledBy, labelId]).join(' ') || null}
                  aria-describedby={!disabled && ariaDescribedBy || null}
                  disabled={disabled}
                  checked={value === option.value}
                  onChange={() => onChange(option.value)}
                />
              );
            })}
          </Form.Group>
        }
      </Field>
    );
  }
}
