import {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {PropTypes as MobXPropTypes} from 'mobx-react';
import {Form, Button, Icon} from 'semantic-ui-react';
import {uniqueId, isObject, isFinite} from 'lodash';

export default class MultiItemsControl extends Component {
  static propTypes = {
    items: MobXPropTypes.arrayOrObservableArray.isRequired,
    children: PropTypes.func.isRequired,
    addItem: PropTypes.func.isRequired,
    removeItem: PropTypes.func.isRequired,
    isItemRemovable: PropTypes.func.isRequired,
    minItems: PropTypes.number.isRequired,
    maxItems: PropTypes.number,
    disabled: PropTypes.bool,
    noItemsMessage: PropTypes.node,
    buttonText: PropTypes.node,
    deleteButtonProps: PropTypes.object,
    addButtonProps: PropTypes.object,
  };

  static defaultProps = {
    minItems: 0,
    noItemsMessage: 'No items.',
    buttonText: 'Add Item',
    isItemRemovable: () => true,
  };

  itemKeys = new WeakMap();

  getItemKey({item, index}) {
    let itemKey = index;
    if (isObject(item)) {
      itemKey = this.itemKeys.get(item);
      if (!itemKey) {
        itemKey = uniqueId('item');
        this.itemKeys.set(item, itemKey);
      }
    }
    return itemKey;
  }

  render() {
    const {
      items, disabled, isItemRemovable,
      addItem, removeItem, minItems, maxItems, header, noItemsMessage, buttonText,
      children, addButtonProps, deleteButtonProps,
    } = this.props;
    return (
      <Fragment>
        {!!items.length && header}
        {items.length ?
          items.map((item, index) => {
            const params = {item, index, disabled};
            const removable = !disabled && isItemRemovable(item, index);
            return (
              <Form.Group key={this.getItemKey(params)}>
                {children(params)}
                {items.length > minItems &&
                  <Form.Field key='remove' className='no-input-field'>
                    <Icon
                      link={removable}
                      color={removable ? 'red' : 'grey'}
                      name='remove'
                      disabled={!removable}
                      onClick={() => removeItem(params)}
                      aria-label='Remove'
                      {...deleteButtonProps}
                    />
                  </Form.Field>
                }
              </Form.Group>
            );
          })
        :
          <div className='no-items-message'>{noItemsMessage}</div>
        }
        <Button
          type='button'
          color='teal'
          size='tiny'
          icon='add'
          labelPosition='left'
          disabled={isFinite(maxItems) && items.length >= maxItems}
          content={buttonText}
          onClick={() => addItem()}
          {...addButtonProps}
        />
      </Fragment>
    );
  }
}
