import {map, get, transform} from 'lodash';
import Ajv from 'ajv';
import ExtendableError from 'es6-error';

export class FormValidationError extends ExtendableError {
  constructor(errors = []) {
    super('Error');
    Object.assign(this, {errors});
  }

  toMessage() {
    return {title: this.message, content: this.errors};
  }
}

export function formSchemaToJSONSchema(formSchema, additionalProperties = true) {
  return transform(formSchema, (result, {name, schema, required}) => {
    result.properties[name] = schema;
    if (required) result.required.push(name);
  }, {type: 'object', properties: {}, required: [], additionalProperties});
}

export function validateFormProperties(schema, properties) {
  const ajv = new Ajv({allErrors: true});
  const valid = ajv.validate(schema, properties);
  return valid ? null : ajvErrorsToFormErrors(schema, ajv.errors);
}

export function ajvErrorsToFormErrors(schema, errors) {
  return map(errors, (error) => {
    const message = getErrorMessage(error);
    if (error.instancePath) {
      // FIXME(vkramskikh): support nested properties
      const propertyName = error.instancePath.slice(1);
      const entityName = get(schema, ['properties', propertyName, 'title'], propertyName);
      return {type: 'property', propertyName, message: `${entityName} ${message}`};
    } else {
      return {type: 'generic', message};
    }
  });
}

export function getErrorMessage({keyword, message, params = {}}) {
  if (keyword === 'minLength' && params.limit === 1) {
    return 'must not be empty';
  }
  return message;
}
