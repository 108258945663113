import {Component, cloneElement} from 'react';
import {scaleLinear, scaleTime} from 'd3';
import {action, computed, makeObservable, observable} from 'mobx';
import {observer} from 'mobx-react';
import {Axis} from '@visx/axis';
import {Grid} from '@visx/grid';
import {Group} from '@visx/group';
import {constant, min, clamp, isFunction} from 'lodash';
import cx from 'classnames';

import {formatChartAxisTime, formatNumber} from '../../formatters';
import SvgTextLengthMeasurer from './SvgTextLengthMeasurer';

import './NumericChartLayout.less';

@observer
export default class NumericChartLayout extends Component {
  static defaultProps = {
    mode: 'compact',
    minValue: 0,
    maxValue: 0,
    units: '',
    dimensions: {
      compact: {
        height: 60,
        margin: {top: 4, right: 3, bottom: 5, left: 40},
        numTicksRows: 4
      },
      expanded: {
        height: 300,
        margin: {top: 10, right: 3, bottom: 30, left: 40},
        numTicksRows: 10
      },
    },
    maxSamplesDisplayCircles: 150,
    yAxisLeftLabelWidth: 10,
    inlineUnitsMaxLength: 2
  };

  xScale = scaleTime();
  yScale = scaleLinear();

  @observable unitsLength = 0;

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  @computed
  get unitsPosition() {
    const {units, inlineUnitsMaxLength} = this.props;
    const unitsLength = units?.length;
    if (!unitsLength) {
      return null;
    } else if (unitsLength <= inlineUnitsMaxLength) {
      return 'inline';
    }
    return 'left';
  }

  @computed
  get yAxisLabelProps() {
    const {unitsPosition, props: {mode, dimensions}} = this;
    const {margin} = dimensions[mode];
    if (unitsPosition === 'left') {
      return {textAnchor: 'middle', y: -margin.left};
    }
    return null;
  }

  @action
  onMeasureUnitsLength = (length) => {
    this.unitsLength = length;
  };

  formatYTick = (value) => {
    const {unitsPosition, props: {units}} = this;
    const yTickUnits = unitsPosition === 'inline' ? units : '';
    return formatNumber(value, {units: yTickUnits, short: true, withIndent: true});
  };

  render() {
    const {
      xScale, yScale, yAxisLabelProps, unitsPosition, unitsLength,
      formatYTick, onMeasureUnitsLength,
      props: {
        children, mode, dimensions, className,
        minValue, maxValue, width: chartWidth,
        timelineStartTime, timelineEndTime,
        units, yAxisLeftLabelWidth
      }
    } = this;
    const {height: chartHeight, margin, numTicksRows: chartNumTicksRows} = dimensions[mode];
    const marginLeft = margin.left +
      (unitsPosition === 'left' ? yAxisLeftLabelWidth : unitsPosition === 'inline' ? unitsLength : 0);
    const xMax = chartWidth - marginLeft - margin.right;
    const yMax = chartHeight - margin.top - margin.bottom;
    xScale.domain([timelineStartTime, timelineEndTime]).rangeRound([0, xMax]);
    yScale.domain([minValue, maxValue]).rangeRound([yMax, 0]);
    const yScaleMin = minValue > 0 ? yScale(minValue) : yScale(min([minValue, 0]));

    const showTicks = chartWidth > 0;
    const numTicksRows = showTicks ? clamp(Math.abs(maxValue) + Math.abs(minValue), 1, chartNumTicksRows) : 0;
    const numTicksColumns = showTicks && mode === 'expanded' ? this.props.numTicksColumns : 0;

    const childrenProps = {
      xScale, yScale, yScaleMin, yMax, xMax
    };

    return (
      <svg className={cx('numeric-chart-layout', className)} width={chartWidth} height={chartHeight}>
        <Group top={margin.top} left={marginLeft}>
          {isFunction(children) ? children(childrenProps) : cloneElement(children, childrenProps)}
          <Axis
            axisClassName='timeline-axis axis-bottom'
            orientation='bottom'
            top={yScaleMin}
            scale={xScale}
            numTicks={numTicksColumns}
            tickFormat={formatChartAxisTime}
            tickLabelProps={constant({})}
          />
          <Axis
            axisClassName='timeline-axis axis-left'
            orientation='left'
            scale={yScale}
            numTicks={numTicksRows}
            label={unitsPosition === 'left' ? units : null}
            labelProps={yAxisLabelProps}
            tickFormat={formatYTick}
            tickLabelProps={constant({dx: '-0.25em', dy: '0.25em'})}
          />
        </Group>
        {showTicks &&
          <Grid
            className='timeline-grid'
            top={margin.top}
            left={marginLeft}
            width={xMax}
            height={yMax}
            xScale={xScale}
            yScale={yScale}
            numTicksRows={numTicksRows}
            numTicksColumns={numTicksColumns}
            stroke={null}
          />
        }
        <SvgTextLengthMeasurer value={units} style={{fontSize: 10}} onMeasure={onMeasureUnitsLength} />
      </svg>
    );
  }
}
