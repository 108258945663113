import {Component, Fragment, cloneElement} from 'react';
import {computed, makeObservable} from 'mobx';
import {observer} from 'mobx-react';
import {isFunction, map, noop} from 'lodash';

import {formatNumber} from '../../formatters';
import {brandColorNames} from '../../brandColorNames';
import MultipleChartHoverHandler from './MultipleChartHoverHandler';
import NumericChartLayout from './NumericChartLayout';

@observer
export default class MultipleChartContainer extends Component {
  static defaultProps = {
    valueKeyName: 'value',
    highlightChartLine: noop,
    resetHighlightChartLine: noop,
    nodeRefs: new WeakMap(),
    itemColors: brandColorNames,
  };

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  @computed get itemColors() {
    const {itemColors, items} = this.props;
    const colorsLength = itemColors.length;
    return map(items, (item, index) => itemColors[index % colorsLength]);
  }

  formatValue = (value) => {
    const {units} = this.props;
    const shortNumber = formatNumber(value, {units, short: true});
    const longNumber = formatNumber(value, {units, short: false});
    return shortNumber === longNumber ? shortNumber : `${shortNumber} (${longNumber})`;
  };

  render() {
    const {
      formatValue, itemColors,
      props: {
        mode, dimensions,
        items, itemSamplesPath, itemSamplesTimes,
        minValue, maxValue, width: chartWidth,
        showPopup, hidePopup, popupContentItemKeys,
        timelineStartTime, timelineEndTime,
        timeIndicators, valueKeyName, units, yAxisLeftLabelWidth,
        inlineUnitsMaxLength, sampleTimes, itemPropertiesPath,
        children, className, combiningGraphsMode, numTicksColumns,
        highlightChartLine, resetHighlightChartLine, nodeRefs, updateRefs, popupRenderers,
      }
    } = this;
    return (
      <NumericChartLayout
        className={className}
        mode={mode}
        dimensions={dimensions}
        minValue={minValue}
        maxValue={maxValue}
        width={chartWidth}
        showPopup={showPopup}
        hidePopup={hidePopup}
        timelineStartTime={timelineStartTime}
        timelineEndTime={timelineEndTime}
        timeIndicators={timeIndicators}
        units={units}
        inlineUnitsMaxLength={inlineUnitsMaxLength}
        yAxisLeftLabelWidth={yAxisLeftLabelWidth}
        numTicksColumns={numTicksColumns}
      >
        {({xScale, yScale, yScaleMin, yMax, xMax}) => {
          const childrenProps = {xScale, yScale, itemColors, yMax};
          return (
            <Fragment>
              <MultipleChartHoverHandler
                items={items}
                itemSamplesPath={itemSamplesPath}
                itemSamplesTimes={itemSamplesTimes}
                itemColors={itemColors}
                sampleTimes={sampleTimes}
                width={Math.abs(xMax)}
                height={yMax}
                yScale={yScale}
                yScaleMin={yScaleMin}
                xScale={xScale}
                valueKeyName={valueKeyName}
                formatValue={formatValue}
                showPopup={showPopup}
                hidePopup={hidePopup}
                combiningGraphsMode={combiningGraphsMode}
                highlightChartLine={highlightChartLine}
                resetHighlightChartLine={resetHighlightChartLine}
                nodeRefs={nodeRefs}
                updateRefs={updateRefs}
                popupRenderers={popupRenderers}
                popupContentItemKeys={popupContentItemKeys}
                itemPropertiesPath={itemPropertiesPath}
              >
                {isFunction(children) ? children(childrenProps) : cloneElement(children, childrenProps)}
              </MultipleChartHoverHandler>
            </Fragment>
          );
        }}
      </NumericChartLayout>
    );
  }
}
