import {useContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Menu, Sticky} from 'semantic-ui-react';
import {isUndefined, noop} from 'lodash';
import cx from 'classnames';

import LayoutContext from '../LayoutContext';
import DropdownControl from './DropdownControl';
import ActionsMenu from './ActionsMenu';

import './Pagination.less';

export const DEFAULT_PAGE_SIZES = [5, 10, 25, 50, 100];
export const DEFAULT_PAGE_SIZE = 25;

const Pagination = ({sticky, ...props}) => {
  const onPageChange = (activePage) => {
    const {onChange, pageSize} = props;
    onChange({activePage, pageSize});
  };

  const onPageSizeChange = (pageSize) => {
    const {onChange} = props;
    onChange({activePage: 1, pageSize});
  };

  return (
    <>
      <PaginationControls
        {...props}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
      />
      <StickyPagination sticky={sticky}>
        <PaginationControls
          {...props}
          isStickyPaginationView
          onPageChange={onPageChange}
          onPageSizeChange={onPageSizeChange}
        />
      </StickyPagination>
    </>
  );
};

Pagination.propTypes = {
  size: Menu.propTypes.size,
  disabled: PropTypes.bool,
  activePage: PropTypes.number,
  totalCount: PropTypes.number,
  pageSizes: PropTypes.arrayOf(PropTypes.number),
  pageSize: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  separateButtons: PropTypes.bool,
  sticky: PropTypes.bool,
  hasPageChangeControl: PropTypes.bool,
  hasPageSizeControl: PropTypes.bool,
};

Pagination.defaultProps = {
  hasPageChangeControl: true,
  hasPageSizeControl: true,
};

export const PaginationControls = ({
  size, disabled, activePage, totalCount, pageSize, pageSizes, separateButtons,
  hasPageChangeControl, hasPageSizeControl, onPageChange, onPageSizeChange,
  isStickyPaginationView,
}) => {
  const classNames = cx('pagination-control', {separate: separateButtons});
  return (
    <div className={classNames}>
      {(hasPageChangeControl || isStickyPaginationView) &&
        <PaginationPageChangeControl
          disabled={disabled}
          activePage={activePage}
          pageSize={pageSize}
          size={size}
          totalCount={totalCount}
          separateButtons={separateButtons}
          onPageChange={onPageChange}
        />
      }
      {(hasPageSizeControl || isStickyPaginationView) &&
        <PaginationPageSizeControl
          disabled={disabled}
          pageSize={pageSize}
          pageSizes={pageSizes}
          onPageSizeChange={onPageSizeChange}
        />
      }
    </div>
  );
};

export const PaginationPageSizeControl = ({
  disabled = false, pageSize = DEFAULT_PAGE_SIZE, pageSizes = DEFAULT_PAGE_SIZES, onPageSizeChange
}) =>
  pageSizes.length > 1 && (
    <span className='page-size'>
      <strong key='page-size-text' aria-hidden>{'Page Size: '}</strong>
      <DropdownControl
        key='page-size-dropdown'
        aria-label='Page Size'
        disabled={disabled}
        value={pageSize}
        options={pageSizes.map((pageSize) => ({
          key: pageSize, value: pageSize, text: pageSize
        }))}
        onChange={(value) => onPageSizeChange(value)}
      />
    </span>
  );

export const PaginationPageChangeControl = ({
  disabled = false, activePage = 1, pageSize = DEFAULT_PAGE_SIZE,
  size = 'mini', totalCount = 0, separateButtons = false, onPageChange
}) => {
  if (isUndefined(totalCount)) return null;
  const fromItem = totalCount ? (activePage - 1) * pageSize + 1 : 0;
  const toItem = totalCount ? Math.min(fromItem + pageSize - 1, totalCount) : 0;
  const totalPages = totalCount ? Math.ceil(totalCount / pageSize) : 0;
  const leftChevronDescription = {
    title: 'Previous page',
    icon: 'left chevron',
    onClick: () => onPageChange(activePage - 1),
    disabled: disabled || !totalCount || activePage === 1,
    popupPosition: 'top center',
  };
  const rightChevronDescription = {
    title: 'Next page',
    icon: 'right chevron',
    onClick: () => onPageChange(activePage + 1),
    disabled: disabled || !totalCount || activePage === totalPages,
    popupPosition: 'top center',
  };
  const pages = (
    <span>
      <strong>
        {fromItem}
        {fromItem === toItem ? '' : `-${toItem}`}
      </strong>
      {' of '}
      <strong>{totalCount}</strong>
    </span>
  );

  return (
    <span className='active-page'>
      {
        separateButtons ?
          <>
            <ActionsMenu
              pagination
              size={size}
              items={[leftChevronDescription]}
            />
            {pages}
            <ActionsMenu
              pagination
              size={size}
              items={[rightChevronDescription]}
            />
          </>
        :
          <>
            {pages}
            <ActionsMenu
              pagination
              size={size}
              items={[leftChevronDescription, rightChevronDescription]}
            />
          </>
      }
    </span>
  );
};

export const StickyPagination = ({sticky = true, children}) => {
  const [isStickActive, setIsStickActive] = useState(false);
  const context = useContext(LayoutContext);
  const updateIsPageWithStickyNavigation = context?.updateIsPageWithStickyNavigation || noop;
  const {footerHeight = 0} = context || {};

  useEffect(() => {
    if (sticky) {
      updateIsPageWithStickyNavigation(true);
    }
    return () => {
      if (sticky) {
        updateIsPageWithStickyNavigation(false);
      }
    };
  }, [sticky, updateIsPageWithStickyNavigation]);

  return (
    <>
      <Sticky onStick={() => setIsStickActive(true)} onUnstick={() => setIsStickActive(false)} />
      {sticky && isStickActive &&
        <div className='sticky-pagination-wrapper' style={{bottom: footerHeight}}>
          {children}
        </div>
      }
    </>
  );
};

export default Pagination;
