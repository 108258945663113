import cx from 'classnames';
import {Component, Fragment, forwardRef} from 'react';
import {LinePath, AreaClosed} from '@visx/shape';
import {curveLinear} from '@visx/curve';
import {RectClipPath} from '@visx/clip-path';
import {action, makeObservable, observable} from 'mobx';
import {observer} from 'mobx-react';
import {forEach, map, isNil, uniqueId, filter, includes} from 'lodash';

import MultipleChartContainer from './MultipleChartContainer';
import {COMBINE_GRAPHS_MODE, CHART_VALUE_CIRCLE_RADIUS, CHART_ELEMENTS_STROKE_WIDTH, LINE_MODE} from './consts';

import './MultipleLineChart.less';

@observer
export default class MultipleLineChart extends Component {
  static defaultProps = {
    itemPropertiesPath: 'properties',
    maxSamplesDisplayCircles: 150,
    minSpaceBetweenCircles: CHART_VALUE_CIRCLE_RADIUS * 2 + CHART_ELEMENTS_STROKE_WIDTH, // allow overlap with 1 corner
  };

  @observable hoveredNodeIndex = null;

  multipleLineChartId = uniqueId('multiple-line-chart-id-');
  nodeRefs = new WeakMap();
  positiveClipPathId = uniqueId(`${this.multipleLineChartId}-positive-clip-path-id-`);
  negativeClipPathId = uniqueId(`${this.multipleLineChartId}-negative-clip-path-id-`);

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  showValueCircles = (item) => {
    const {width: chartWidth, itemSamplesPath, maxSamplesDisplayCircles, minSpaceBetweenCircles} = this.props;
    if (chartWidth <= 0) return false;
    if (item.lineMode === LINE_MODE.LINES) return false;
    return (includes([LINE_MODE.LINES_MARKERS, LINE_MODE.MARKERS], item.lineMode)) ||
      (item[itemSamplesPath].length < maxSamplesDisplayCircles &&
        item[itemSamplesPath].length < chartWidth / minSpaceBetweenCircles);
  };

  @action
  highlightChartLine = (line) => {
    if (!isNil(line)) this.hoveredNodeIndex = line;
  };

  @action
  resetHighlightChartLine = () => {
    if (!isNil(this.hoveredNodeIndex)) this.hoveredNodeIndex = null;
  };

  updateRefs(ref, nodeIndex) {
    if (ref) this.nodeRefs.set(ref, nodeIndex);
  }

  render() {
    const {
      hoveredNodeIndex, multipleLineChartId, positiveClipPathId, negativeClipPathId, showValueCircles,
      highlightChartLine, resetHighlightChartLine,
      props: {
        mode, dimensions,
        items, itemSamplesPath, sampleTimes, itemSamplesTimes,
        minValue, maxValue, width: chartWidth,
        showPopup, hidePopup, timelineStartTime, timelineEndTime,
        timeIndicators, valueKeyName, units, yAxisLeftLabelWidth, popupContentItemKeys,
        inlineUnitsMaxLength, numTicksColumns, fill, popupRenderers, itemPropertiesPath,
        itemColors
      }
    } = this;
    const childProps = {
      mode, dimensions, numTicksColumns,
      items, itemSamplesPath, itemSamplesTimes,
      minValue, maxValue, width: chartWidth,
      showPopup, hidePopup, popupContentItemKeys,
      timelineStartTime, timelineEndTime,
      timeIndicators, valueKeyName, units, yAxisLeftLabelWidth,
      inlineUnitsMaxLength, sampleTimes, popupRenderers, itemPropertiesPath,
      nodeRefs: this.nodeRefs, combiningGraphsMode: COMBINE_GRAPHS_MODE.LINEAR,
      highlightChartLine, resetHighlightChartLine, updateRefs: this.updateRefs,
      itemColors,
    };

    return (
      <MultipleChartContainer
        className='multiple-line-chart'
        {...childProps}
      >
        {({xScale, yScale, itemColors, yMax}) => {
          const linesData = [];
          const fillings = [];
          const linesMasksData = [];
          const yAxisFillClipPaths = [];
          const valueCirclesData = {};
          const valueCirclesMasks = [];

          if (chartWidth > 0) {
            forEach(items, (item, itemIndex) => {
              const samples = item[itemSamplesPath];
              const singleItem = samples.length === 1;
              const linePathData = map(samples, (sample, sampleIndex) =>
                [xScale(itemSamplesTimes[itemIndex][sampleIndex]), yScale(sample[valueKeyName])]);
              const color = item?.lineMode === LINE_MODE.MARKERS ?
                'transparent' : itemColors[itemIndex];
              // chart lines
              linesData.push({
                key: `line-${itemIndex}`,
                lineIndex: itemIndex,
                innerRef: (ref) => this.updateRefs(ref, itemIndex),
                color,
                highlight: !isNil(hoveredNodeIndex) && itemIndex === hoveredNodeIndex,
                shadow: !isNil(hoveredNodeIndex) && itemIndex !== hoveredNodeIndex,
                data: linePathData,
                chartId: multipleLineChartId,
              });
              // chart value circles
              forEach(samples, (sample, sampleIndex) => {
                const sampleTime = itemSamplesTimes[itemIndex][sampleIndex];
                const x = xScale(sampleTime);
                const y = yScale(sample[valueKeyName]);
                if (showValueCircles(item) && (singleItem || sampleIndex !== samples.length - 1)) {
                  valueCirclesData[`${itemIndex}-${sampleIndex}`] = {
                    key: `value-circle-${itemIndex}-${sampleIndex}`,
                    centerX: x,
                    centerY: y,
                    color: itemColors[itemIndex],
                    shadow: !isNil(hoveredNodeIndex) && hoveredNodeIndex !== itemIndex,
                    highlight: !isNil(hoveredNodeIndex) && hoveredNodeIndex === itemIndex,
                    ref: (ref) => this.updateRefs(ref, itemIndex),
                  };
                  const mask = {
                    key: `value-circle-mask-for-${itemIndex}-${sampleIndex}`,
                    centerX: x,
                    centerY: y,
                    isMask: true,
                  };
                  if (valueCirclesMasks[itemIndex]) {
                    valueCirclesMasks[itemIndex].push(mask);
                  } else {
                    valueCirclesMasks[itemIndex] = [mask];
                  }
                }
              });

              // chart line's fill
              if (fill) {
                linesMasksData.push({
                  key: `line-mask-${itemIndex}`,
                  data: linePathData,
                  isMask: true,
                });

                fillings.push(
                  <Fragment key={`filled-line-${itemIndex}`}>
                    <AreaClosed
                      key='positive-filling'
                      data={linePathData}
                      x={0}
                      y={0}
                      yScale={yScale}
                      className={cx(
                        'multiple-line-chart-filling',
                        `graph-fill-color-${color}`,
                        {shadow: !isNil(hoveredNodeIndex) && hoveredNodeIndex !== itemIndex},
                      )}
                      curve={curveLinear}
                      clipPath={`url(#${positiveClipPathId})`}
                      mask={`url(#${multipleLineChartId}-mask-for-${itemIndex})`}
                    />
                    <AreaClosed
                      key='negative-filling'
                      data={linePathData}
                      x={0}
                      y={0}
                      y0={0}
                      yScale={yScale}
                      className={cx(
                        'multiple-line-chart-filling',
                        `graph-fill-color-${color}`,
                        {shadow: !isNil(hoveredNodeIndex) && hoveredNodeIndex !== itemIndex},
                      )}
                      curve={curveLinear}
                      clipPath={`url(#${negativeClipPathId})`}
                      mask={`url(#${multipleLineChartId}-mask-for-${itemIndex})`}
                    />
                  </Fragment>
                );
              }
            });

            // fill clip paths
            if (fill) {
              yAxisFillClipPaths.push(
                ...[
                  <RectClipPath
                    key='positive-filling-clip-path'
                    id={positiveClipPathId}
                    x={0}
                    y={-1}
                    width={chartWidth}
                    height={yScale(0) + 1}
                  />,
                  <RectClipPath
                    key='negative-filling-clip-path'
                    id={negativeClipPathId}
                    x={0}
                    y={yScale(0)}
                    width={chartWidth}
                    height={yMax}
                  />
                ]
              );
            }
          }

          return (
            <Fragment>
              {/* add lines and values' circles mask for every filling to hide the visual overlap */}
              {fill && map(items, (_, itemIndex) =>
                <mask
                  key={`masks-for-${itemIndex}`}
                  id={`${multipleLineChartId}-mask-for-${itemIndex}`}
                  className='multiple-line-chart-mask'
                >
                  <rect x={0} y={0} width='100%' height='100%' />
                  <ChartLine {...linesMasksData[itemIndex]} />
                  {map(
                    valueCirclesMasks[itemIndex],
                    (props) => <ValueCircle key={props.key} {...props} />
                  )}
                </mask>
              )}
              {/* add values' circles mask for each chart line to hide the visual overlap */}
              {map(valueCirclesMasks, (masks, itemIndex) =>
                <mask
                  key={`value-circles-masks-for-${itemIndex}`}
                  id={`${multipleLineChartId}-value-circles-masks-for-${itemIndex}`}
                  maskUnits='userSpaceOnUse'
                  className='multiple-line-chart-mask'
                >
                  <rect x={0} y={-1} width='100%' height='100%' />
                  {map(masks, (props) => <ValueCircle key={props.key} {...props} />)}
                </mask>
              )}
              {fillings}
              {yAxisFillClipPaths}
              {map(
                filter(linesData, (line) => !line.highlight),
                (props) => <ChartLine key={props.key} {...props} />
              )}
              {map(
                filter(valueCirclesData, (value) => !value.highlight),
                (props) => <ValueCircle key={props.key} {...props} />
              )}
              {/** show the selected line and its values` circles separately, so, they appear on the foreground */}
              {!isNil(hoveredNodeIndex) && map(
                filter(linesData, (line) => line.highlight),
                (props) => <ChartLine key={props.key} {...props} />
              )}
              {map(
                filter(valueCirclesData, (value) => value.highlight),
                (props) => <ValueCircle key={props.key} {...props} />
              )}
            </Fragment>
          );
        }}
      </MultipleChartContainer>
    );
  }
}

const ValueCircle = forwardRef(({centerX, centerY, color, shadow, highlight, isMask}, ref) => {
  return (
    <circle
      {...!isMask && {ref}}
      className={cx(
        'multiple-line-chart-value-circle',
        {
          [`graph-color-${color}`]: !isMask,
          'value-circle-mask': isMask,
          highlight,
          shadow,
        }
      )}
      cx={centerX}
      cy={centerY}
      r={CHART_VALUE_CIRCLE_RADIUS}
    />
  );
});

const ChartLine = ({chartId, lineIndex, shadow, highlight, color, isMask, ...props}) => {
  return (
    <LinePath
      className={cx(
        'multiple-line-chart-line',
        {
          [color]: !isMask,
          'line-mask': isMask,
          shadow,
          highlight
        },
      )}
      curve={curveLinear}
      {...!isMask && {mask: `url(#${chartId}-value-circles-masks-for-${lineIndex})`}}
      {...props}
    />
  );
};
