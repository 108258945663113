import {observer} from 'mobx-react';
import {capitalize, flatMap, isArray, isBoolean, isEmpty, isNil, isNumber, isString, map, size,
  transform} from 'lodash';
import cx from 'classnames';

import './AppliedQuery.less';

const AppliedQuery = (props) => {
  const asText = isString(filters);
  const {filters, ifEmpty = null} = props;

  const filtersAsText = asText ? [filters] : getQueryParts(props);

  if (!isEmpty(filtersAsText)) {
    return (
      <span className={cx('applied-query', {plain: asText})}>
        {flatMap(filtersAsText, (chunk, index) => [index ? ' and ' : null, chunk])}
      </span>
    );
  }
  return ifEmpty;
};

export default observer(AppliedQuery);

// Gets all non-empty query parts
export const getQueryParts = (props) => {
  const {filters, schema: items, renderers = [], valueProps = {}} = props;
  const allRenderers = [
    ...map(renderers, 'getQueryPartData'),
    ...standardQueryPartsGetters
  ];
  return transform(items, (result, {name, schema, match}) => {
    const filter = filters[name];
    if (isBoolean(filter) || !isEmpty(filter) || isFinite(filter)) {
      const part = getQueryPartData({
        name,
        value: filter?.value ?? filter,
        schema,
        match,
        renderers: allRenderers,
        ...valueProps
      });

      if (part) {
        const {value, matcher} = part;
        result.push(
          <span key={name}>
            <strong>{schema?.title ?? name}</strong>
            {` ${matcher} `}
            <strong>{value}</strong>
          </span>
        );
      }
    }
  }, []);
};

const getQueryPartData = (props) => {
  for (const getQueryPartMethod of props.renderers) {
    const part = getQueryPartMethod(props);
    if (!isNil(part)) return part;
  }
  return null;
};

const standardQueryPartsGetters = [
  // Boolean
  (props) => (isBoolean(props?.value) ? {value: capitalize(props.value), matcher: 'is'} : null
  ),
  // Regex
  ({match, value}) => (
    (match === 'regex' && isString(value)) ? {value: `/${value}/`, matcher: '~'} : null
  ),
  // Array
  (props) => {
    const {value, schema} = props;
    if (isArray(value)) {
      const result = transform(value, (acc, subvalue) => {
        const part = getQueryPartData({...props, value: subvalue, schema: schema?.items});
        if (part) {
          acc.push(...isEmpty(acc) ? [part.value] : [', ', part.value]);
        }
      }, []);
      if (size(result)) return {value: ['[', ...result, ']'], matcher: 'in'};
    }
    return null;
  },
  // Primitive
  ({value}) => {
    if (isString(value)) return {value: `"${value}"`, matcher: '='};
    if (isNumber(value)) return {value, matcher: '='};
    return null;
  }
];
