import {Component, Fragment} from 'react';
import {Form, Button, Grid, List, Modal, Input} from 'semantic-ui-react';
import {isNumber, isFinite, find, map, keys, sum, transform, isNil} from 'lodash';
import moment from 'moment';
import {observer} from 'mobx-react';
import {observable, action, computed, reaction, makeObservable} from 'mobx';
import PropTypes from 'prop-types';
import cx from 'classnames';
import pluralize from 'pluralize';

import DropdownControl from './DropdownControl';
import Field from './Field';
import {formatSeconds} from '../formatters/formatDate';
import {createValueRenderer} from '../createValueRenderer';
import CalendarControl from './CalendarControl';

import './DurationInput.less';

const MINUTE_IN_SECONDS = 60;
const HOUR_IN_SECONDS = MINUTE_IN_SECONDS * 60;
const DAY_IN_SECONDS = HOUR_IN_SECONDS * 24;
const timeDisplayFormat = 'MMM D, YYYY HH:mm';

@observer
export default class DurationInput extends Component {
  static propTypes = {
    name: PropTypes.string,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    durations: PropTypes.arrayOf(PropTypes.shape({
      text: PropTypes.string,
      value: PropTypes.number
    })),
    schema: PropTypes.object,
    requireStartDate: PropTypes.bool,
    requireEndDate: PropTypes.bool,
    value: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.shape({
        start: PropTypes.string,
        end: PropTypes.string,
      })
    ]),
    errors: PropTypes.arrayOf(PropTypes.string),
    textPrefix: PropTypes.string,
    customValueType: PropTypes.oneOf(['dates', 'duration']),
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
  };

  static defaultProps = {
    durations: [
      {text: '1 Minute', value: MINUTE_IN_SECONDS},
      {text: '2 Minutes', value: MINUTE_IN_SECONDS * 2},
      {text: '5 Minutes', value: MINUTE_IN_SECONDS * 5},
      {text: '10 Minutes', value: MINUTE_IN_SECONDS * 10},
      {text: '30 Minutes', value: MINUTE_IN_SECONDS * 30},
      {text: '1 Hour', value: HOUR_IN_SECONDS},
      {text: '3 Hours', value: HOUR_IN_SECONDS * 3},
      {text: '6 Hours', value: HOUR_IN_SECONDS * 6},
      {text: '12 Hours', value: HOUR_IN_SECONDS * 12},
      {text: '1 Day', value: DAY_IN_SECONDS},
      {text: '7 Days', value: DAY_IN_SECONDS * 7},
      {text: '30 Days', value: DAY_IN_SECONDS * 30},
    ],
    requireStartDate: true,
    requireEndDate: false,
    textPrefix: 'Last ',
    placeholder: 'Select Duration',
  };

  @observable.ref selectionOpen = false;
  @observable.ref advancedSelectionOpen = false;
  @observable.ref duration = 0;
  @observable range = {start: null, end: null};

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  @computed
  get text() {
    if (isNil(this.props.value)) {
      return null;
    }
    const {value, durations} = this.props;
    if (isNumber(value)) {
      const {textPrefix, selectedValueLabel} = this.props;
      const duration = find(durations, {value});
      const prefix = (textPrefix || selectedValueLabel || '');
      return `${prefix}${duration?.text ?? formatSeconds(value, {slice: null})}`;
    } else if (value?.start) {
      const start = moment(value.start).format(timeDisplayFormat);
      const end = value.end ? moment(value.end).format(timeDisplayFormat) : 'Now';
      return `${start} - ${end}`;
    }
    return null;
  }

  @computed
  get options() {
    return [
      ...map(this.props.durations,
        ({text, value}, index) => ({text: `${this.props.textPrefix || ''}${text}`, value, key: index})
      ),
      ...(this.props.customValueType ?
          [{key: 'custom', text: 'Custom', value: null, onClick: this.onAdvancedSelectionOpen}] : []
      )
    ];
  }

  @computed
  get isValidRange() {
    return (!this.props.requireStartDate || !!this.range?.start) &&
      (!this.props.requireEndDate || !!this.range?.end);
  }

  @computed
  get canMoveDayForward() {
    const {value} = this.props;
    const maxDate = moment().add(-1, 'day');
    return this.isRange &&
      maxDate.isSameOrAfter(value?.start) && (!value?.end || maxDate.isSameOrAfter(value?.end));
  }

  @computed
  get isRange() {
    const {value} = this.props;
    return !!value && !isNumber(value);
  }

  @computed
  get isApplyCustomValueDisabled() {
    return this.props.customValueType === 'dates' && !this.isValidRange;
  }

  @action
  onSelectionOpen = () => {
    if (this.isRange) {
      this.onAdvancedSelectionOpen();
    } else {
      this.selectionOpen = true;
    }
  };

  @action
  onSelectionClose = () => {
    this.selectionOpen = false;
  };

  @action
  onAdvancedSelectionOpen = () => {
    this.advancedSelectionOpen = true;
  };

  @action
  onAdvancedSelectionClose = () => {
    this.advancedSelectionOpen = false;
  };

  @action
  resetState = () => {
    this.range = this.isRange ? this.props.value : {};
    this.duration = this.props.value;
  };

  @action
  onSetRangeValue = (field, value) => {
    this.range[field] = value;
  };

  @action
  onSetDurationValue = (value) => {
    this.duration = value;
  };

  @action
  shiftRangeDay = (days) => {
    const {value} = this.props;
    if (this.isRange) {
      const range = {
        start: value?.start ? moment.utc(value.start).add(days, 'day').format() : null,
        end: value?.end ? moment.utc(value.end).add(days, 'day').format() : null,
      };
      this.onChange(range);
    }
  };

  applyCustomValue = () => {
    this.onAdvancedSelectionClose();
    if (this.props.customValueType === 'dates') {
      this.onChange(this.range);
    } else if (this.props.customValueType === 'duration') {
      this.onChange(this.duration);
    }
  };

  onChange = (value) => {
    this.onSelectionClose();
    this.props.onChange(value);
  };

  render() {
    const {
      text, range, duration, options, isApplyCustomValueDisabled, resetState, shiftRangeDay, canMoveDayForward,
      selectionOpen, onSelectionOpen, onSelectionClose, onSetRangeValue, onSetDurationValue,
      advancedSelectionOpen, onAdvancedSelectionClose, isRange, onChange, applyCustomValue,
    } = this;
    const {
      name, value, schema, required, disabled, errors, durations,
      customValueType, placeholder, additionLabel, fieldProps, selectedValueLabel
    } = this.props;

    return (
      <Field
        className={cx('duration-input', {'custom-range': isRange})}
        label={schema?.title ?? name}
        description={schema?.description}
        errors={errors}
        required={required}
        disabled={disabled}
        {...fieldProps}
      >
        {(inputProps) =>
          <Fragment>
            {isRange &&
              <Button
                basic
                attached='left'
                icon='chevron left'
                disabled={disabled}
                onClick={() => shiftRangeDay(-1)}
                aria-label='Decrease by 1 day'
              />
            }
            <DropdownControl
              open={selectionOpen}
              text={text}
              value={isNumber(value) ? value : null}
              placeholder={placeholder}
              additionLabel={additionLabel}
              selectedValueLabel={selectedValueLabel}
              fluid
              options={options}
              onOpen={onSelectionOpen}
              onClose={onSelectionClose}
              onChange={onChange}
              {...inputProps}
            />
            {isRange &&
              <Button
                basic
                attached='right'
                icon='chevron right'
                disabled={disabled || !canMoveDayForward}
                onClick={() => shiftRangeDay(1)}
                aria-label='Increase by 1 day'
              />
            }
            <Modal
              className='duration-input-modal'
              id='duration-input-modal'
              open={advancedSelectionOpen}
              onMount={resetState}
              size='small'
              closeIcon
              closeOnDimmerClick={false}
              onClose={onAdvancedSelectionClose}
            >
              <Modal.Header>{placeholder}</Modal.Header>
              <Modal.Content>
                <AdvancedSelection
                  duration={duration}
                  value={value}
                  start={range?.start}
                  end={range?.end}
                  durations={durations}
                  customValueType={customValueType}
                  onSetRangeValue={onSetRangeValue}
                  onSetDurationValue={onSetDurationValue}
                  onChange={onChange}
                  onClose={onAdvancedSelectionClose}
                />
              </Modal.Content>
              <Modal.Actions>
                <Button className='ghost' onClick={onAdvancedSelectionClose}>
                  {'Cancel'}
                </Button>
                <Button
                  content='Apply'
                  primary
                  disabled={isApplyCustomValueDisabled}
                  onClick={applyCustomValue}
                />
              </Modal.Actions>
            </Modal>
          </Fragment>
        }
      </Field>
    );
  }
}

function AdvancedSelection({
  start, end, duration, durations, customValueType,
  onSetRangeValue, onSetDurationValue, onClose, onChange,
}) {
  return (
    <Grid divided>
      <Grid.Row columns={2}>
        <Grid.Column width={4}>
          <List selection verticalAlign='middle'>
            {map(durations, ({text, value}) =>
              <List.Item
                key={value}
                active={value === duration}
                onClick={() => {
                  if (customValueType === 'duration') {
                    onSetDurationValue(value);
                  } else {
                    onClose();
                    onChange(value);
                  }
                }}
              >
                {text}
              </List.Item>
            )}
          </List>
        </Grid.Column>
        <Grid.Column width={12}>
          {customValueType === 'dates' &&
            <AdvancedSelectionAsDates start={start} end={end} onSetRangeValue={onSetRangeValue} />}
          {customValueType === 'duration' &&
            <AdvancedSelectionAsDuration value={duration} onChange={onSetDurationValue} />}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

class AdvancedSelectionAsDates extends Component {
  now = moment.utc().toDate();

  render() {
    const {now, props: {start, end, onSetRangeValue}} = this;
    return (
      <Form>
        <Form.Group inline>
          <CalendarControl
            placeholder='Start Date'
            value={start}
            maxDate={end || now}
            onChange={(value) => onSetRangeValue('start', value)}
          />
          <Form.Field className='no-input-field'>{'—'}</Form.Field>
          <CalendarControl
            placeholder='End Date'
            value={end}
            minDate={start}
            maxDate={now}
            onChange={(value) => onSetRangeValue('end', value)}
          />
        </Form.Group>
      </Form>
    );
  }
}

@observer
class AdvancedSelectionAsDuration extends Component {
  units = ['days', 'hours', 'minutes', 'seconds'];

  @observable duration = transform(this.units, (acc, unit) => {acc[unit] = 0;}, {});

  constructor(props) {
    super(props);

    makeObservable(this);

    this.disposeValueReaction = reaction(
      () => this.props.value,
      this.updateLocalDuration,
      {fireImmediately: true}
    );
  }

  componentWillUnmount() {
    this.disposeValueReaction();
  }

  @action
  updateLocalDuration = () => {
    const momentDuration = moment.duration(this.props.value, 's');
    this.units.forEach((unit) => {
      this.duration[unit] = Math.floor(momentDuration[unit === 'days' ? 'asDays' : unit]());
    });
  };

  @action
  onChange = (value, unit) => {
    this.duration[unit] = value !== '' && isFinite(Number(value)) ? Number(value) : '';
  };

  @action
  onBlur = (unit) => {
    this.duration[unit] = this.duration[unit] || 0;
    const seconds = sum(
      keys(this.duration).map(
        (key) => this.duration[key] ? moment.duration(1, key).asSeconds() * this.duration[key] : 0
      ));
    this.props.onChange(seconds);
  };

  render() {
    const {units, duration, onBlur} = this;

    return (
      <Form className='advanced-selection-duration'>
        {units.map((unit) => (
          <Field key={unit} label={pluralize(unit, duration[unit])}>
            <Input
              type='number'
              min={0}
              value={duration[unit]}
              onChange={(e) => this.onChange(e.target.value, unit)}
              onBlur={() => onBlur(unit)}
            />
          </Field>
        ))}
      </Form>
    );
  }
}

export const durationRenderer = createValueRenderer({
  condition: ({name}) => name === 'retention_duration',
  renderValueInput({name, value, schema, required, disabled, errors, onChange}) {
    return (
      <DurationInput
        name={name}
        value={value}
        schema={schema}
        required={required}
        disabled={disabled}
        errors={errors}
        onChange={onChange}
        durations={[
          {text: '1 Minute', value: MINUTE_IN_SECONDS},
          {text: '5 Minutes', value: MINUTE_IN_SECONDS * 5},
          {text: '10 Minutes', value: MINUTE_IN_SECONDS * 10},
          {text: '30 Minutes', value: MINUTE_IN_SECONDS * 30},
          {text: '1 Hour', value: HOUR_IN_SECONDS},
          {text: '3 Hours', value: HOUR_IN_SECONDS * 3},
          {text: '6 Hours', value: HOUR_IN_SECONDS * 6},
          {text: '12 Hours', value: HOUR_IN_SECONDS * 12},
          {text: '1 Day', value: DAY_IN_SECONDS},
          {text: '7 Days', value: DAY_IN_SECONDS * 7},
          {text: '30 Days', value: DAY_IN_SECONDS * 30},
          {text: '60 Days', value: DAY_IN_SECONDS * 60},
          {text: '90 Days', value: DAY_IN_SECONDS * 90},
          {text: '180 Days', value: DAY_IN_SECONDS * 180},
          {text: '360 Days', value: DAY_IN_SECONDS * 360},
        ]}
      />
    );
  }
});
