import {Component, createRef} from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react';
import {observable, reaction, action, makeObservable} from 'mobx';
import {uniqueId, debounce, isUndefined, isEmpty, isEqual} from 'lodash';
import AceEditor from 'react-ace';
import ResizeDetector from 'react-resize-detector';
import cx from 'classnames';

import 'ace-builds/src-min-noconflict/ext-language_tools';
import 'ace-builds/src-min-noconflict/ext-searchbox';

import setSessionErrors from '../setSessionErrors';

import '../ace-theme-apstra-light';
import '../ace-base-completer';
import './CodeEditorControl.less';

@observer
export default class CodeEditorControl extends Component {
  static propTypes = {
    value: PropTypes.string,
    mode: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    height: PropTypes.string,
    width: PropTypes.string,
    padding: PropTypes.number,
    multiLine: PropTypes.bool,
    wrapEnabled: PropTypes.bool,
    minLines: PropTypes.number,
    maxLines: PropTypes.number,
    showGutter: PropTypes.bool,
    format: PropTypes.bool,
    validate: PropTypes.bool,
    validationDelay: PropTypes.number,
    onChange: PropTypes.func,
    onValidate: PropTypes.func,
    enableCompletion: PropTypes.bool,
    useWorker: PropTypes.bool,
    aceEditorOptions: PropTypes.object,
    markers: PropTypes.array,
    actions: PropTypes.node,
  };

  static defaultProps = {
    mode: 'text',
    multiLine: false,
    showGutter: false,
    format: true,
    validate: false,
    width: '100%',
    height: '17px', // input's line-height
    padding: 6,
    validationDelay: 500,
    enableCompletion: false,
    aceEditorOptions: {},
    markers: [],
    actions: [],
  };

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  @observable formattedValue = this.props.value;
  valueFormattedFor = this.props.value;
  editorRef = createRef();
  containerRef = createRef();
  editorId = uniqueId('CodeEditor');
  @observable.ref validationErrors = [];

  disposeValueFormatterReaction = reaction(
    () => this.props.value,
    (value) => {
      if (value !== this.valueFormattedFor) {
        this.valueFormattedFor = value;
        this.setFormattedValue(this.formatValue(value));
      } else {
        this.setFormattedValue(value);
      }
    }
  );

  @action
  setFormattedValue(formattedValue) {
    this.formattedValue = formattedValue;
  }

  formatValue(value, multiLine = this.props.multiLine) {
    if (this.props.format) {
      const editor = this.editorRef.current?.editor;
      if (editor) {
        const mode = editor.getSession().getMode();
        if (mode.format) {
          return mode.format(value, multiLine);
        }
      }
    }
    return value;
  }

  validateValue = debounce(action((value) => {
    if (value.length) {
      const editor = this.editorRef.current?.editor;
      if (editor) {
        const session = editor.getSession();
        const mode = session.getMode();
        if (mode.validate) {
          this.validationErrors = mode.validate(value);
          setSessionErrors(session, this.validationErrors);
        }
      }
    }
  }), this.props.validationDelay, {leading: false, trailing: true});

  @action
  clearValidationErrors = () => {
    this.validationErrors = [];
    const session = this.editorRef.current?.editor?.getSession();
    session.setAnnotations([]);
    setSessionErrors(session, []);
  };

  focus() {
    const editor = this.editorRef.current?.editor;
    if (editor) editor.focus();
  }

  aceEditorOptions = {
    useWorker: false,
    tabSize: 2,
    useSoftTabs: true,
    behavioursEnabled: true,
    cursorStyle: 'slim',
    ...this.props.aceEditorOptions,
  };

  aceEditorSingleLineOptions = {
    ...this.aceEditorOptions,
    maxLines: 1,
    wrapBehavioursEnabled: false,
    indentedSoftWrap: false,
  };

  aceEditorMultiLineOptions = {
    ...this.aceEditorOptions,
    wrapBehavioursEnabled: true,
    indentedSoftWrap: true,
  };

  onLoad = (editor) => {
    const {padding, 'aria-label': ariaLabel, 'aria-labelledby': ariaLabelledBy, placeholder} = this.props;
    const textAreaNode = Array.from(editor.container.childNodes).find((node) => node.type === 'textarea');
    if (ariaLabel) {
      textAreaNode.setAttribute('aria-label', ariaLabel);
    } else if (ariaLabelledBy) {
      textAreaNode.setAttribute('aria-labelledby', ariaLabelledBy);
    } else if (placeholder) {
      textAreaNode.setAttribute('placeholder', placeholder);
    } else {
      textAreaNode.setAttribute('aria-label', 'Code Editor');
    }
    editor.renderer.setScrollMargin(padding, padding);
    editor.renderer.setPadding(padding);
    this.handleCompleter(editor);
    editor.commands.on('afterExec', ({editor, command}) => {
      if (command.name === 'backspace' || command.name === 'insertstring') {
        const {completer} = editor.getSession().getMode();
        if (completer && completer.checkForCustomCharacterCompletions) {
          completer.checkForCustomCharacterCompletions(editor);
        }
      }
      if (command.name === 'submit') {
        const {completer} = editor.getSession().getMode();
        if (completer && completer.hideCompletionsPopup) {
          completer.hideCompletionsPopup(editor);
        }
      }
    });
    const {format} = editor.getSession().getMode();
    if (format) {
      const value = editor.getValue();
      if (value) {
        const formattedValue = format(value, this.props.multiLine);
        if (value !== formattedValue) {
          this.valueFormattedFor = value;
          this.setFormattedValue(formattedValue);
        }
      }
    }
    if (!this.props.multiLine) {
      editor.commands.bindKey('Enter|Shift-Enter', 'null');
      editor.on('paste', (e) => {
        e.text = e.text.replace(/[\r\n]+/g, ' ');
      });
    } else {
      // do not submit form on pressing Enter key
      editor.keyBinding.addKeyboardHandler({
        handleKeyboard: (data, hash, keyString, keyCode, event) => {
          if (event?.key === 'Enter') {
            event.stopPropagation();
            return true;
          }
        }
      });
    }

    /* Run validation on load once */
    if (this.props.validate) this.validateValue(this.formattedValue);
  };

  handleCompleter(editor = this.editorRef.current?.editor) {
    if (editor) {
      const {completer} = editor.getSession().getMode();
      if (completer) {
        completer.params = this.props.completerParams;
        editor.completers = [completer];
      }
    }
  }

  handleResize(editor = this.editorRef.current?.editor) {
    if (editor) {
      editor.resize();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.mode !== prevProps.mode || !isEqual(this.props.completerParams, prevProps.completerParams)) {
      this.handleCompleter();
    }
  }

  componentWillUnmount() {
    this.disposeValueFormatterReaction();
  }

  onChange = (value) => {
    if (!this.props.useWorker) {
      if (this.props.validate) {
        this.clearValidationErrors();
        this.validateValue(value);
      }
    }
    this.valueFormattedFor = value;
    this.props.onChange(value);
  };

  onCursorChange = ({
    session = this.editorRef.current?.editor.getSession(),
    cursor = this.editorRef.current?.editor.getCursorPosition(),
    $isEmpty: isSelectionEmpty,
  }) => {
    if (this.props.onCursorChange) {
      this.props.onCursorChange({
        session,
        cursor,
        isSelectionEmpty: isUndefined(isSelectionEmpty) ? true : isSelectionEmpty,
      });
    }
  };

  onBlur = () => {
    if (this.props.onCursorChange) {
      this.props.onCursorChange({});
    }
  };

  render() {
    const {disabled, width, height, mode, placeholder, commands, multiLine, wrapEnabled, showGutter,
      enableCompletion, useWorker, minLines, maxLines = 500, markers,
      actions} = this.props;
    return (
      <div
        ref={this.containerRef}
        className={
          cx('code-editor-control', {
            empty: !this.formattedValue,
            'has-action-icon': !isEmpty(actions),
            'action-group': actions.length > 1,
          })
        }
      >
        <ResizeDetector targetRef={this.containerRef} handleWidth onResize={() => this.handleResize()} />
        <AceEditor
          ref={this.editorRef}
          name={this.editorId}
          value={this.formattedValue}
          onChange={this.onChange}
          onBlur={this.onBlur}
          onCursorChange={this.onCursorChange}
          onFocus={this.onCursorChange}
          onLoad={this.onLoad}
          placeholder={placeholder}
          readOnly={disabled}
          commands={commands}
          onValidate={this.props.onValidate}
          setOptions={{
            ...(multiLine ? this.aceEditorMultiLineOptions : this.aceEditorSingleLineOptions),
            useWorker,
          }}
          theme='apstra-light'
          mode={mode}
          width={width}
          height={height}
          showPrintMargin={false}
          showGutter={showGutter}
          highlightActiveLine={multiLine}
          wrapEnabled={isUndefined(wrapEnabled) ? multiLine : wrapEnabled}
          enableLiveAutocompletion={enableCompletion}
          minLines={minLines}
          maxLines={maxLines}
          markers={markers}
        />
        {actions}
      </div>
    );
  }
}
