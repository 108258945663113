import {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {Button, Divider, Grid, Icon, Menu, Popup, Segment} from 'semantic-ui-react';
import {castArray, compact, isEmpty, map, size, delay, isString} from 'lodash';
import {observer} from 'mobx-react';
import {action, computed, makeObservable, observable} from 'mobx';
import copy from 'copy-to-clipboard';

import FetchDataError from './FetchDataError';
import Loader from './Loader';
import Pagination from './Pagination';
import {keyPressClick} from '../keyHandlers';
import AppliedQuery, {getQueryParts} from './AppliedQuery';
import SearchBox from './SearchBox';

import './DataFilteringLayout.less';

@observer
export default class DataFilteringLayout extends Component {
  static propTypes = {
    loaderVisible: PropTypes.bool,
    fetchDataError: PropTypes.instanceOf(Error),
    showCopyButton: PropTypes.bool,
    hideActionsAndPagination: PropTypes.bool,

    extraContent: PropTypes.shape({
      // Additional view options in 'Table Settings' popup
      viewOptions: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
      // Extra buttons on the right from main buttons
      buttonsPanel: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
      // Extra rows on top of the standard set (view options & pagination)
      headerRow: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)])
    }),

    paginationProps: PropTypes.shape({
      activePage: PropTypes.number,
      pageSize: PropTypes.number,
      pageSizes: PropTypes.arrayOf(PropTypes.number),
      totalCount: PropTypes.number,
      onChange: PropTypes.func
    }),

    SearchComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
    searchProps: PropTypes.shape({
      filters: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      schema: PropTypes.array,
      valueProps: PropTypes.object,
      valueInputProps: PropTypes.object,
      onChange: PropTypes.func,
      renderers: PropTypes.arrayOf(PropTypes.object),
      // Plain text query must still be passed as an object
      plainInputProp: PropTypes.string
    })
  };

  static defaultProps = {
    showCopyButton: false,
    hideActionsAndPagination: false,
    SearchComponent: SearchBox
  };

  @observable
  selectedOption = null;

  @computed
  get isSearchPlainText() {
    const filters = this.props.searchProps?.filters;
    const plainInputProp = this.props.searchProps?.plainInputProp;
    return isString(plainInputProp ? filters[plainInputProp] : filters);
  }

  @action
  setSearchMode(isPlainText) {
    this.isSearchPlainText = isPlainText;
  }

  @computed
  get filtersCount() {
    const props = this.props?.searchProps ?? {};
    const {filters} = props;
    return isString(filters) ?
      (size(filters) > 0 ? 1 : 0) :
      size(getQueryParts(props));
  }

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  @computed.struct get searchBoxErrors() {
    const {searchBoxErrors, fetchDataError} = this.props;
    if (searchBoxErrors) {
      return searchBoxErrors;
    }
    if (fetchDataError) {
      const {response, responseBody} = fetchDataError;
      if (response && response.status === 422 && responseBody && responseBody.errors) {
        return responseBody.errors;
      }
    }
    return {};
  }

  @computed.struct
  get buttons() {
    const {paginationProps, SearchComponent, searchProps, extraContent} = this.props;

    const result = [
      {
        label: 'Table Settings',
        icon: 'ellipsis horizontal',
        content: <TablePropertiesWrapper
          viewOptions={extraContent?.viewOptions ?? null}
          paginationProps={paginationProps}
          onClose={this.toggleMenu}
        />
      }
    ];
    if (searchProps?.onChange && !this.isSearchPlainText) {
      result.push({
        label: 'Search',
        icon: 'search',
        title: this.filtersCount ? `Filters applied: ${this.filtersCount}` : '',
        content: <SearchBoxWrapper
          SearchComponent={SearchComponent}
          onClose={this.toggleMenu}
          {...searchProps}
        />
      });
    }
    return result;
  }

  @action
  toggleMenu = (event, {label} = {}) => {
    this.selectedOption = this.selectedOption === label ? undefined : label;
  };

  @observable
  copyButtonLabel = 'Copy';

  @action
  copy = () => {
    copy(document.location.href);
    this.copyButtonLabel = 'Copied';
    delay(action(() => {
      this.copyButtonLabel = 'Copy';
    }), 5000);
  };

  @action
  clearQuery = () => {
    this.props.searchProps?.onChange?.({});
  };

  getClickHandler(label) {
    return keyPressClick(this.toggleMenu, null, {label});
  }

  render() {
    const {searchBoxErrors} = this;
    const {children, fetchDataError, paginationProps, searchProps, SearchComponent, loaderVisible,
      extraContent, showCopyButton, hideActionsAndPagination} = this.props;

    return (
      <Grid stackable className='data-filtering-layout'>
        {
          extraContent?.headerRow ?? null
        }
        {
          !hideActionsAndPagination &&
            <Grid.Row columns={2} className='data-table-options'>
              <Grid.Column width={10} className='data-filtering-options'>
                <Menu compact size='tiny'>
                  {
                    map(this.buttons, ({label, icon, title, content}) => {
                      const isOpen = this.selectedOption === label;
                      return (
                        <Popup
                          key={label}
                          on={[]}
                          className='table-property'
                          content={content}
                          open={isOpen}
                          eventsEnabled={false}
                          onClose={this.toggleMenu}
                          position='bottom left'
                          inverted={false}
                          trigger={
                            <Menu.Item
                              icon
                              active={isOpen}
                              onClick={this.toggleMenu}
                              onKeyDown={this.getClickHandler(label)}
                              label={label}
                              aria-label={label}
                              tabIndex={0}
                              role='button'
                            >
                              <Popup
                                content={label}
                                position='top center'
                                offset={[0, 10]}
                                trigger={
                                  <div className='labelled-icon'>
                                    <Icon name={icon} />
                                    <span>{title}</span>
                                  </div>
                                }
                              />
                            </Menu.Item>
                          }
                        />
                      );
                    })
                  }
                </Menu>
                {
                  this.isSearchPlainText && <SearchComponent {...searchProps} />
                }
                {
                  extraContent?.buttonsPanel ?? null
                }
              </Grid.Column>
              {
                paginationProps &&
                  <Grid.Column textAlign='right' width={6}>
                    <Pagination
                      key='page-change-control'
                      {...paginationProps}
                      hasPageSizeControl={false}
                    />
                  </Grid.Column>
              }
            </Grid.Row>
        }
        {
          this.filtersCount > 0 && !this.isSearchPlainText &&
            <Grid.Row className='applied-query-row'>
              <Grid.Column>
                <Segment>
                  <div>
                    {'Applied Query: '}
                    <AppliedQuery {...searchProps} />
                  </div>
                  <div>
                    {showCopyButton &&
                      <Button primary size='small' onClick={this.copy}>
                        <Icon name='copy' />
                        {this.copyButtonLabel}
                      </Button>
                    }
                    <Button size='small' onClick={this.clearQuery}>
                      <Icon name='undo' />
                      {'Clear'}
                    </Button>
                  </div>
                </Segment>
              </Grid.Column>
            </Grid.Row>
        }
        <Grid.Row>
          <Grid.Column>
            {loaderVisible ?
              <Loader />
            :
              !isEmpty(searchBoxErrors) ?
                null
              :
                fetchDataError ?
                  <FetchDataError error={fetchDataError} />
                :
                  children
            }
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

const TablePropertiesWrapper = observer(({onClose, paginationProps, viewOptions}) => {
  return (
    <div className='data-filtering-wrapper'>
      <div className='corner-buttons'>
        <Button icon='close' onClick={onClose} />
      </div>
      <h4>{'View Options'}</h4>
      <div>
        <Divider />
        <h5>{'Page Size'}</h5>
        <Pagination
          key='page-size-control'
          {...paginationProps}
          sticky={false}
          hasPageChangeControl={false}
        />
        {
          map(compact(castArray(viewOptions)), (element, index) => (
            <Fragment key={index}>
              <Divider />
              {element}
            </Fragment>
          ))
        }
      </div>
    </div>
  );
});

const SearchBoxWrapper = observer(({SearchComponent, onClose, onChange, ...props}) => {
  const onChangeWithClose = (...props) => {
    onChange(...props);
    onClose();
  };
  return (
    <div className='data-filtering-wrapper search'>
      <div className='corner-buttons'>
        <Button icon='close' onClick={onClose} />
      </div>
      <h4>{'Query'}</h4>
      <Divider />

      <SearchComponent
        key='search-box'
        onChange={onChangeWithClose}
        {...props}
      />
    </div>
  );
});
