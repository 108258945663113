import {Component, cloneElement, Children, isValidElement} from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react';
import {computed, makeObservable} from 'mobx';
import {Form, Popup, Icon} from 'semantic-ui-react';
import {isEmpty, isFunction, compact, uniqueId, toString} from 'lodash';

import ValidationErrors from './ValidationErrors';

import './Field.less';

@observer
export default class Field extends Component {
  static propTypes = {
    ...Form.Field.propTypes,
    fieldId: PropTypes.string,
    label: PropTypes.node,
    description: PropTypes.node,
    descriptionAsTooltip: PropTypes.bool,
    errors: PropTypes.arrayOf(PropTypes.string),
    errorsPosition: PropTypes.oneOf(['above', 'below']),
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.func, PropTypes.node]),
  };

  static defaultProps = {
    errors: [],
    errorsPosition: 'below',
  };

  @computed get fieldId() {
    return uniqueId();
  }

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  breakIntoLines(text) {
    return toString(text).split('\n').map((line, index) => <p key={index}>{line}</p>);
  }

  render() {
    const {
      label, description, disabled, errors, errorsPosition, children, descriptionAsTooltip,
      fieldId: providedFieldId,
      ...fieldProps
    } = this.props;
    let renderedLabel, renderedDescription;
    const fieldId = providedFieldId ?? this.fieldId;
    const childrenProps = {disabled};
    const labelControls = compact([
      label,
      (description && descriptionAsTooltip) &&
        <Popup
          key='popup'
          trigger={<Icon name='info circle' aria-label={description} />}
          content={this.breakIntoLines(description)}
          position='right center'
          wide
        />
    ]);
    if (!isEmpty(labelControls)) {
      const labelId = 'label' + fieldId;
      renderedLabel = <label id={labelId}>{labelControls}</label>;
      childrenProps['aria-labelledby'] = labelId;
    }
    if (!descriptionAsTooltip && description) {
      const descriptionId = 'description' + fieldId;
      renderedDescription = <div id={descriptionId} className='form-text'>{description}</div>;
      if (!disabled) childrenProps['aria-describedby'] = descriptionId;
    }
    const updatedChildren = isFunction(children) ? children(childrenProps) :
      (isValidElement(children) && Children.count(children) === 1) ? cloneElement(children, childrenProps) :
      children;
    return (
      <Form.Field
        {...fieldProps}
        disabled={disabled}
        error={!isEmpty(errors)}
      >
        {renderedLabel}
        {errorsPosition === 'above' && <ValidationErrors errors={errors} pointing='below' />}
        {updatedChildren}
        {errorsPosition === 'below' && <ValidationErrors errors={errors} pointing='above' />}
        {renderedDescription}
      </Form.Field>
    );
  }
}
