import {Component} from 'react';
import PropTypes from 'prop-types';
import {computed, makeObservable} from 'mobx';
import {observer} from 'mobx-react';
import {flatMap, castArray, flatten, values, isPlainObject} from 'lodash';

import Field from './Field';
import MultiItemsControl from './MultiItemsControl';

@observer
export default class ListInput extends Component {
  static propTypes = {
    minItems: PropTypes.number,
    generateNewEntry: PropTypes.func,
    fieldWidth: PropTypes.number,
  };

  static defaultProps = {
    value: [],
    generateNewEntry: () => '',
  };

  setItemValue = (index, newValue) => {
    this.props.onChange(this.props.value.map((oldValue, itemIndex) => index === itemIndex ? newValue : oldValue));
  };

  removeItem = ({index}) => {
    this.props.onChange(this.props.value.filter((value, itemIndex) => index !== itemIndex));
  };

  addItem = () => {
    this.props.onChange([...this.props.value, this.props.generateNewEntry()]);
  };

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  @computed get errors() {
    return flatMap(this.props.errors, (error) =>
      isPlainObject(error) ? flatten(values(error)) : castArray(error)
    );
  }

  render() {
    const {
      name, value, schema, required, disabled, header, buttonText,
      noItemsMessage, minItems, isItemRemovable, fieldWidth,
      children
    } = this.props;
    const {errors} = this;
    return (
      <Field
        className='multi-items-input'
        label={schema?.title ?? name}
        description={schema?.description}
        required={required}
        disabled={disabled}
        errors={errors}
        errorsPosition='above'
        width={fieldWidth}
      >
        {(inputProps) =>
          <MultiItemsControl
            items={value}
            header={header}
            buttonText={buttonText}
            noItemsMessage={noItemsMessage}
            minItems={minItems}
            isItemRemovable={isItemRemovable}
            addItem={this.addItem}
            removeItem={this.removeItem}
            disabled={disabled}
          >
            {({item: value, index}) => children({
              value,
              index,
              onChange: (value) => this.setItemValue(index, value),
              ...inputProps
            })}
          </MultiItemsControl>
        }
      </Field>
    );
  }
}
