import {Component} from 'react';
import {Form} from 'semantic-ui-react';
import {get} from 'lodash';
import moment from 'moment';

import Field from './Field';
import CalendarControl from './CalendarControl';
import createValueRenderer from '../createValueRenderer';

export default class TimeRangeInput extends Component {
  setDate = (key, date = null) => {
    const value = {...this.props.value};
    if (date) {
      value[key] = date;
    } else {
      delete value[key];
    }
    this.props.onChange(value);
  };

  render() {
    const {name, value, schema, required, disabled, serializeDates, displayFormat} = this.props;
    const start = get(value, ['start'], null);
    const end = get(value, ['end'], null);
    return (
      <Field
        label={schema?.title ?? name}
        description={schema?.description}
        required={required}
        disabled={disabled}
      >
        {(inputProps) =>
          <Form.Group>
            <Form.Field width={8}>
              <CalendarControl
                {...inputProps}
                placeholder='Start time'
                value={start}
                maxDate={end}
                serializeDates={serializeDates}
                displayFormat={displayFormat}
                onChange={(date) => this.setDate('start', date)}
              />
            </Form.Field>
            <Form.Field width={8}>
              <CalendarControl
                {...inputProps}
                placeholder='End time'
                value={end}
                minDate={start}
                serializeDates={serializeDates}
                displayFormat={displayFormat}
                onChange={(date) => this.setDate('end', date)}
              />
            </Form.Field>
          </Form.Group>
        }
      </Field>
    );
  }
}

export const timeDisplayFormat = 'MMM D, YYYY HH:mm';
export const timeRangeRenderer = createValueRenderer({
  condition: ({name}) => name === 'timestamp',
  renderValue({value: {start, end}}) {
    if (start && end) {
      return `${moment(start).format(timeDisplayFormat)} — ${moment(end).format(timeDisplayFormat)}`;
    } else if (!start && end) {
      return `till ${moment(end).format(timeDisplayFormat)}`;
    } else if (start && !end) {
      return `since ${moment(start).format(timeDisplayFormat)}`;
    }
  },
  renderValueInput({name, value, schema, required, disabled, onChange}) {
    return (
      <TimeRangeInput
        name={name}
        value={value}
        schema={schema}
        required={required}
        disabled={disabled}
        onChange={onChange}
        serializeDates
        displayFormat={timeDisplayFormat}
      />
    );
  }
});
