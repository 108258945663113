import {Component, createRef, Fragment, PureComponent} from 'react';
import {action, computed, makeObservable, observable} from 'mobx';
import {observer} from 'mobx-react';
import {forEach, min, map, minBy, isNil, sortBy, filter, max, reduce, isUndefined, isEmpty, find} from 'lodash';
import cx from 'classnames';
import bounds from 'binary-search-bounds';
import pluralize from 'pluralize';

import Value from '../Value';
import {formatDateAsLocalDateTimeMS} from '../../formatters';
import {COMBINE_GRAPHS_MODE, CHART_VALUE_CIRCLE_RADIUS} from './consts';
import GenericChartHoverHandler from './GenericChartHoverHandler';

import './MultipleChartHoverHandler.less';

@observer
export default class MultipleChartHoverHandler extends Component {
  static defaultProps = {
    itemSamplesPath: 'samples',
    combiningGraphsMode: COMBINE_GRAPHS_MODE.LINEAR,
    edgeOffset: 80,
  };

  highlights = [];
  @observable highlightProps = null;

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  @computed
  get filteredItems() {
    const {items, itemSamplesPath} = this.props;
    return filter(items, (item) => item[itemSamplesPath]);
  }

  @computed
  get highlighted() {
    return !isNil(this.highlightProps?.sampleX);
  }

  @action
  resetHighlight = () => {
    this.highlightProps = null;
    this.highlights = [];
    this.props.hidePopup();
    this.props.resetHighlightChartLine();
  };

  getRefreshHighlightFn = ({x, hoveredNode, reset, popupAnchorRef}) => {
    return action(() => {
      const {
        filteredItems,
        props: {
          itemSamplesPath, itemSamplesTimes, sampleTimes, xScale, yScaleMin,
          yScale, valueKeyName, nodeRefs,
        }
      } = this;
      const xTimeStamp = xScale.invert(x);
      const geIndex = bounds.ge(sampleTimes, xTimeStamp);
      const leIndex = geIndex > 0 ? geIndex - 1 : geIndex;

      const sampleTimesIndex = minBy(
        [geIndex, leIndex],
        (idx) => Math.abs(xScale(sampleTimes[idx]) - x)
      );
      const timestamp = sampleTimes[sampleTimesIndex];
      const timestampNum = +timestamp;

      this.highlights = [];
      if (timestamp) {
        forEach(filteredItems, (item, itemIndex) => {
          const itemSampleTimes = map(itemSamplesTimes[itemIndex], (t) => +t);
          const sampleIndex = bounds.eq(itemSampleTimes, timestampNum);
          if (sampleIndex > -1) {
            const sample = item[itemSamplesPath][sampleIndex];
            const sampleY = min([yScale(sample?.[valueKeyName]), yScaleMin]);
            this.highlights.push({item, itemIndex, sample, sampleY});
          }
        });
      }

      const highlightSamplesProps = {
        timestamp,
        hoveredNode,
        sampleX: xScale(sampleTimes[sampleTimesIndex]),
        hoveredNodeIndex: null,
      };

      if (hoveredNode) {
        const index = nodeRefs.get(hoveredNode);
        if (index >= 0) {
          highlightSamplesProps.hoveredNodeIndex = index;

          // When hover over a node whose sample is not included in the current list of highlights but its position is
          // close enought to hover over (this case can be reproduced when `Aggregation` filter is set to `off`),
          // we force to display the data of the closest to the cursor position hovered node sample

          const hoveredHighlight = find(this.highlights, (highlight) => highlight.itemIndex === index);
          if (!hoveredHighlight) {
            const hoveredNodeItem = find(filteredItems, (_, idx) => idx === index);
            const itemSampleTimesNum = map(itemSamplesTimes[index], (t) => +t);

            const pastSampleId = bounds.le(itemSampleTimesNum, timestampNum);
            const nextSampleId = bounds.ge(itemSampleTimesNum, timestampNum);

            const pastSample = hoveredNodeItem[itemSamplesPath][pastSampleId];
            const nextSample = hoveredNodeItem[itemSamplesPath][nextSampleId];

            const pastSampleTimestampNum = +itemSamplesTimes[index][pastSampleId];
            const nextSampleTimestampNum = +itemSamplesTimes[index][nextSampleId];

            const highlightedSample = !isNaN(pastSampleTimestampNum) && !isNaN(nextSampleTimestampNum)
              ? timestampNum - pastSampleTimestampNum <= Math.abs(timestampNum - nextSampleTimestampNum)
                ? {sampleId: pastSampleId, sample: pastSample}
                : {sampleId: nextSampleId, sample: nextSample}
              : isNaN(pastSampleTimestampNum)
                ? {sampleId: nextSampleId, sample: nextSample}
                : {sampleId: pastSampleId, sample: pastSample};

            this.highlights = [{
              item: hoveredNodeItem,
              itemIndex: index,
              sample: highlightedSample.sample,
              sampleY: min([yScale(highlightedSample.sample[valueKeyName]), yScaleMin])
            }];
            highlightSamplesProps.sampleX = xScale(itemSamplesTimes[index][highlightedSample.sampleId]);
          }
        }
      }
      if (!isNil(highlightSamplesProps.hoveredNodeIndex)) {
        this.props.highlightChartLine(highlightSamplesProps.hoveredNodeIndex);
      } else {
        this.props.resetHighlightChartLine();
      }

      if (this.highlights.length) {
        this.highlightProps = highlightSamplesProps;
        this.showPopup(highlightSamplesProps, popupAnchorRef);
      } else {
        reset();
      }
    });
  };

  showPopup = ({timestamp}, node) => {
    const {highlights, highlightProps: {hoveredNodeIndex}, props: {
      showPopup, valueKeyName, formatValue, itemColors, popupContentItemKeys, combiningGraphsMode, edgeOffset
    }} = this;
    const maxContentSize = {};
    const el = node?.current;
    if (el) {
      const {top, bottom, left, right, width} = el.getBoundingClientRect();
      const {innerHeight, innerWidth} = window;
      maxContentSize.height = max([top - edgeOffset, innerHeight - bottom - edgeOffset]);
      maxContentSize.width = max([left - width, innerWidth - right - width]);
    }
    const popupDescription = {
      custom: true,
      node: node?.current,
      timestamp,
      header: formatDateAsLocalDateTimeMS(timestamp),
      content: this.renderPopupContent({
        highlights, valueKeyName, popupContentItemKeys, combiningGraphsMode, itemColors,
        formatValue, maxContentSize, hoveredNodeIndex,
      }),
      popupProps: {
        flowing: true,
        position: undefined,
      },
    };
    showPopup(popupDescription);
  };

  renderPopupContent = ({
    highlights, valueKeyName, popupContentItemKeys, itemColors, formatValue, combiningGraphsMode, maxContentSize,
    hoveredNodeIndex,
  }) => {
    const {popupRenderers, itemPropertiesPath} = this.props;
    const sorted = combiningGraphsMode === COMBINE_GRAPHS_MODE.LINEAR ?
      sortBy(highlights, ({sample}) => -sample[valueKeyName]) :
      combiningGraphsMode === COMBINE_GRAPHS_MODE.STACKED ?
        sortBy(highlights, ({itemIndex}) => -itemIndex) :
        highlights;

    return (
      <ResponsivePopupContent
        maxContentSize={maxContentSize}
        items={sorted}
        formatValue={formatValue}
        itemColors={itemColors}
        valueKeyName={valueKeyName}
        popupContentItemKeys={popupContentItemKeys}
        hoveredNodeIndex={hoveredNodeIndex}
        popupRenderers={popupRenderers}
        itemPropertiesPath={itemPropertiesPath}
      />
    );
  };

  render() {
    const {
      highlightProps, highlights, highlighted, resetHighlight, getRefreshHighlightFn,
      props: {items, width, height, itemColors, combiningGraphsMode, children}
    } = this;
    return (
      <GenericChartHoverHandler
        width={width}
        height={height}
        values={items}
        sampleX={highlightProps?.sampleX}
        hoveredNode={highlightProps?.hoveredNode}
        lineHidden={!highlighted}
        getHandlerFn={getRefreshHighlightFn}
        onReset={resetHighlight}
      >
        {children}
        {combiningGraphsMode === COMBINE_GRAPHS_MODE.LINEAR &&
          map(highlights, ({sampleY, itemIndex}) => (
            (isNil(highlightProps?.hoveredNodeIndex) || highlightProps?.hoveredNodeIndex === itemIndex) &&
              <circle
                key={`highlight-circle ${itemIndex}`}
                ref={(ref) => this.props.updateRefs(ref, itemIndex)}
                className={cx('multiple-chart-hover-handler-circle', itemColors[itemIndex], {hidden: !highlighted})}
                cx={highlightProps?.sampleX}
                cy={sampleY}
                r={CHART_VALUE_CIRCLE_RADIUS}
              />
          ))
        }
      </GenericChartHoverHandler>
    );
  }
}

@observer
class ResponsivePopupContent extends Component {
  static defaultProps = {
    maxHeight: 400,
    maxColumns: 3,
  };

  @observable maxChildContentSize = {};

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  @action
  setChildContentSize = ({width, height}) => {
    this.maxChildContentSize = {
      width: max([this.maxChildContentSize.width, width]),
      height: max([this.maxChildContentSize.height, height]),
    };
  };

  @computed get popupContentSize() {
    if (!this.maxChildContentSize.width || !this.maxChildContentSize.height) return {};
    const {maxContentSize, maxHeight, items, maxColumns} = this.props;

    const maxAvailableHeight = min([maxHeight, maxContentSize.height]);
    const maxAvailableWidth = maxContentSize.width;
    const maxRows = Math.floor(maxAvailableHeight / this.maxChildContentSize.height);
    const columns = min([Math.floor(maxAvailableWidth / this.maxChildContentSize.width), maxColumns]) || 1;
    const rows = min([maxRows, Math.ceil(items.length / columns)]);

    if (maxRows >= items.length) {
      return {
        width: this.maxChildContentSize.width,
        height: this.maxChildContentSize.height * items.length,
        extraItems: 0
      };
    }

    const height = this.maxChildContentSize.height * rows;
    const width = this.maxChildContentSize.width * columns;

    const extraItems = rows * columns >= items.length ? 0 : items.length - rows * columns;
    return {width, height, extraItems};
  }

  @computed get items() {
    const {extraItems} = this.popupContentSize;
    const {items, hoveredNodeIndex} = this.props;
    if (!extraItems || isNil(hoveredNodeIndex)) return items;
    return reduce(items, (result, item) => {
      if (item.itemIndex === hoveredNodeIndex) {
        result.unshift(item);
      } else {
        result.push(item);
      }
      return result;
    }, []);
  }

  render() {
    const {
      formatValue, itemColors, valueKeyName, popupContentItemKeys, hoveredNodeIndex, popupRenderers, itemPropertiesPath,
    } = this.props;
    const {width, height, extraItems} = this.popupContentSize;
    return [
      <div key='content' className='responsive-popup-content' style={{width, height}}>
        {map(this.items, ({item, sample, itemIndex}) =>
          <PopupContentCell
            key={itemIndex}
            item={item}
            color={itemColors[itemIndex]}
            formattedValue={formatValue(sample[valueKeyName])}
            popupContentItemKeys={popupContentItemKeys}
            setChildContentSize={this.setChildContentSize}
            style={{width: this.maxChildContentSize.width}}
            className={cx(
              'popup-content-cell',
              {highlighted: isNil(hoveredNodeIndex) || hoveredNodeIndex === itemIndex}
            )}
            popupRenderers={popupRenderers}
            itemPropertiesPath={itemPropertiesPath}
          />
        )}
      </div>,
      extraItems ? (
        <div key='extra-items' className='extra-items'>
          {`${extraItems} more ${pluralize('item', extraItems)}...`}
        </div>
      ) : null,
    ];
  }
}

class PopupContentCell extends PureComponent {
  ref = createRef();

  componentDidMount() {
    const {width, height} = this.ref.current.getBoundingClientRect();
    this.props.setChildContentSize({width, height});
  }

  render() {
    const {
      item, color, itemPropertiesPath, popupContentItemKeys, formattedValue, className, style, popupRenderers
    } = this.props;
    return (
      <div ref={this.ref} className={className} style={style}>
        <div className={`label ${color}`} />
        {map(popupContentItemKeys, (name, i) => {
          const value = item[itemPropertiesPath]?.[name];
          return !isUndefined(value) && (
            <Fragment key={i}>
              {i !== 0 && (
                <div className='content-item-divider' />
              )}
              <Value
                name={`${itemPropertiesPath}.${name}`}
                value={value}
                renderers={popupRenderers}
              />
            </Fragment>
          );
        })}
        {!isEmpty(popupContentItemKeys) && <div className='content-item-divider' />}
        {formattedValue}
      </div>
    );
  }
}

