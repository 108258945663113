import moment from 'moment';
import {defaultTo} from 'lodash';

export function formatDateAsTimeFromNow(date) {
  return moment(date).fromNow();
}

export function formatDateAsLocalTime(date) {
  return moment(date).format(moment.HTML5_FMT.TIME_SECONDS);
}

export function formatChartAxisTime(date) {
  const momentDate = moment(date);
  if (momentDate.isSame(momentDate.clone().startOf('day'))) {
    return momentDate.format(moment.HTML5_FMT.DATE);
  }
  return momentDate.format(moment.HTML5_FMT.TIME_SECONDS);
}

export function formatDateAsLocalDateTime(date) {
  return moment(date).format(moment.HTML5_FMT.DATE + ' ' + moment.HTML5_FMT.TIME);
}

export function formatDateAsLocalDateTimeMS(date) {
  return moment(date).format(moment.HTML5_FMT.DATE + ' ' + moment.HTML5_FMT.TIME_MS);
}

export function formatInterval(interval, {unit, slice} = {}) {
  const formatIntervalPart = (value, period) => value + ' ' + (value === 1 ? period.slice(0, -1) : period);
  const duration = moment.duration(interval, defaultTo(unit, 'ms'));
  const format = (units) => units
    .map((period) => formatIntervalPart(Math.floor(duration[period === 'days' ? 'asDays' : period]()), period))
    .filter((d) => +d.charAt(0))
    .slice(0, slice === null ? undefined : defaultTo(slice, 2))
    .join(' ');
  return format(['days', 'hours', 'minutes', 'seconds', 'milliseconds']) || '0 seconds';
}

export function formatSeconds(interval, {slice} = {}) {
  return formatInterval(interval, {unit: 'seconds', slice});
}
