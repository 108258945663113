import React, {Component} from 'react';
import {observer} from 'mobx-react';
import {observable, computed, action, reaction, makeObservable} from 'mobx';
import {Popup} from 'semantic-ui-react';
import {
  map, findIndex, isEmpty, castArray, isFunction, flatten, isArray,
  forEach, filter, sortBy, isNumber,
} from 'lodash';
import {withResizeDetector} from 'react-resize-detector';
import cx from 'classnames';

import {formatDateAsLocalDateTimeMS, formatInterval} from '../../formatters';
import parseTimestamp from '../../parseTimestamp';

import './TimelineGraphContainer.less';

@observer
class TimelineGraphContainer extends Component {
  static defaultProps = {
    samples: [],
    expanded: false,
    useCurrentTimeAsTimelineEnd: true,
    xTickStep: 100,
    width: 0,
    itemSamplesPath: 'samples'
  };

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  @computed get items() {
    const {items, samples, itemSamplesPath} = this.props;
    if (isArray(items)) {
      return filter(items, (item) => item[itemSamplesPath]);
    }
    return [{[itemSamplesPath]: samples}];
  }

  @computed get samples() {
    const {items, props: {itemSamplesPath}} = this;
    return flatten(map(items, itemSamplesPath));
  }

  @computed get originalSampleTimes() {
    const {samples} = this;
    const sampleTimesSet = new Set();
    forEach(samples, ({timestamp}) => sampleTimesSet.add(timestamp));
    return sortBy(map(Array.from(sampleTimesSet), (timestamp) => parseTimestamp(timestamp)), (date) => +date);
  }

  @computed get sampleTimes() {
    const {originalSampleTimes: times, props: {timeSeriesAggregation}} = this;
    if (isEmpty(times) || !timeSeriesAggregation) {
      return times;
    }
    const firstTime = new Date(times[0]);
    firstTime.setSeconds(firstTime.getSeconds() - timeSeriesAggregation);
    return [firstTime, ...times];
  }

  @computed get itemSamplesTimes() {
    const {items, props: {itemSamplesPath}} = this;
    const times = [];
    forEach(items, (item, itemIndex) => {
      times[itemIndex] = map(item[itemSamplesPath], ({timestamp}) => parseTimestamp(timestamp));
    });
    return times;
  }

  @computed get timelineStartTime() {
    const {sampleTimes} = this;
    if (isEmpty(sampleTimes)) return null;
    return sampleTimes[0];
  }

  @computed get timelineEndTime() {
    const {sampleTimes, props: {useCurrentTimeAsTimelineEnd}} = this;
    if (useCurrentTimeAsTimelineEnd || sampleTimes.length === 1) return this.currentTime;
    if (isEmpty(sampleTimes)) return null;
    return sampleTimes[sampleTimes.length - 1];
  }

  @observable.ref currentTime = new Date();
  @observable.ref popupDescription = null;

  @action
  updateCurrentTime = () => {
    this.currentTime = new Date();
  };

  @action
  showPopup = (params) => {
    const {node, timestamp, header, custom, additionalInfo} = params;
    if (custom) {
      this.showCustomPopup(params);
      return;
    }
    const {getSampleInterval, samples, props: {popupIntervalPrefix}} = this;
    const sampleIndex = findIndex(samples, {timestamp});
    if (sampleIndex !== -1) {
      const interval = getSampleInterval(sampleIndex);
      const content = [formatDateAsLocalDateTimeMS(timestamp)];
      if (sampleIndex > 0 || interval > 0) {
        content.push([popupIntervalPrefix, formatInterval(interval)].join(''));
      }
      if (additionalInfo) {
        content.push(additionalInfo);
      }
      this.popupDescription = {node, timestamp, header, content};
    }
  };

  @action
  showCustomPopup = (params) => {
    this.popupDescription = params;
  };

  @action
  hidePopup = () => {
    this.popupDescription = null;
  };

  getSampleInterval = (sampleIndex) => {
    const {sampleTimes, timelineEndTime, props: {samples, timeSeriesAggregation, useCurrentTimeAsTimelineEnd}} = this;
    if (useCurrentTimeAsTimelineEnd || sampleTimes.length === 1) {
      const sampleTime = sampleTimes[sampleIndex];
      const nextSampleTime = sampleIndex < samples.length - 1 ? sampleTimes[sampleIndex + 1] : null;
      return (nextSampleTime || timelineEndTime) - sampleTime;
    }
    const sampleTimeIndex = isNumber(timeSeriesAggregation) ? sampleIndex + 1 : sampleIndex;
    const sampleTime = sampleTimes[sampleTimeIndex];
    const prevSampleTime = sampleTimes[sampleTimeIndex - 1];
    return sampleTime - (prevSampleTime || (sampleTime - (timeSeriesAggregation || 0) * 1000));
  };

  componentDidMount() {
    this.disposeUpdateTimelineEndOnDataUpdateReaction = reaction(
      () => this.props.samples,
      () => this.props.useCurrentTimeAsTimelineEnd && this.updateCurrentTime(),
      {fireImmediately: true}
    );
  }

  componentWillUnmount() {
    this.disposeUpdateTimelineEndOnDataUpdateReaction();
  }

  render() {
    const {
      timelineStartTime, timelineEndTime,
      showPopup, hidePopup, popupDescription,
      sampleTimes, samples, itemSamplesTimes, items,
      props: {
        children, itemSamplesPath, xTickStep, expanded, width, targetRef,
      }
    } = this;
    if (!timelineStartTime) {
      return (
        <div className='value-container'>
          <div className='no-samples'>
            {'No data'}
          </div>
        </div>
      );
    }
    const childrenProps = {
      timelineStartTime, timelineEndTime,
      showPopup, hidePopup,
      itemSamplesTimes, items, itemSamplesPath, samples, sampleTimes,
      mode: expanded ? 'expanded' : 'compact',
      width: Math.floor(width),
      numTicksColumns: Math.floor(width / xTickStep),
    };
    return (
      <div
        ref={targetRef}
        className={cx('graph-container', {expandable: !expanded})}
        onMouseLeave={hidePopup}
      >
        {isFunction(children) ? children(childrenProps) : React.cloneElement(children, childrenProps)}
        {popupDescription &&
          <Popup
            key={popupDescription.timestamp}
            className='graph-popup'
            size='tiny'
            position='top center'
            context={popupDescription.node}
            open
            inverted={false}
            {...popupDescription.popupProps}
          >
            {popupDescription.header &&
              <Popup.Header>{popupDescription.header}</Popup.Header>
            }
            {popupDescription.content &&
              <Popup.Content>
                {isArray(popupDescription.content) ?
                  map(castArray(popupDescription.content), (line, index) =>
                    <div key={index}>{line}</div>) :
                    isFunction(popupDescription.content) ?
                      popupDescription.content() :
                      React.cloneElement(popupDescription.content)
                }
              </Popup.Content>
            }
          </Popup>
        }
      </div>
    );
  }
}

export default withResizeDetector(TimelineGraphContainer, {handleWidth: true});
